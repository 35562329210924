import 'opsly-web-font/css/opsly.css';
import './fonts/inter.css';
import './fontface.js'
import 'elm-canvas';
import { Elm } from '../elm/Main.elm';

const storageKey = 'session';

const app = Elm.Main.init({
    node: document.querySelector('main'),
    flags: {
        apiDomain: process.env.API_DOMAIN,
        bearerToken: window.localStorage.getItem(storageKey),
        nodeEnv: process.env.NODE_ENV,
        disableExport: process.env.EXPORT_DISABLED == "true",
    }
});

app.ports.store.subscribe(function (value) {
    if (value === null) {
        localStorage.removeItem(storageKey);
    } else {
        localStorage.setItem(storageKey, value);
    }
    app.ports.onStore.send(value);
});

// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener('storage', (event) => {
    if (event.storageArea === localStorage && event.key === storageKey) {
        app.ports.onStore.send(event.newValue);
    }
}, false);

// sockets
const socketConnections = {};

app.ports.sendSocketMessage.subscribe(function (message) {
    switch (message.cmd) {
        case 'connect': {
            const socket = new WebSocket(message.address);

            socket.onmessage = function (event) {
                app.ports.receiveSocketMsg.send(event.data);
            }
            socket.onclose = function () {
                delete socketConnections[message.name];
            };
            socketConnections[message.name] = socket;
            break;
        }
        case 'send': {
            socketConnections[message.name].send(message.payload);
            break;
        }
        case 'close': {
            socketConnections[message.name].close();
            delete socketConnections[message.name];
            break;
        }

    }
});
